import {
  IScopeTwoEmissionsBomsParameters,
  IScopeTwoEmissionsTwelveMonths,
  IScopeTwoEmissionsRequestIDParameters,
  IScopeTwoEmissionsRequestDetail,
  IScopeTwoEmissionsBomsData,
} from "@/models/ScopeTwoEmissionsBoms";

import { ErrorResponse } from "@/services/axios/error";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";

import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { ScopeTwoEmissions } from "@/services/scopeTwoEmissionsBoms";

const name = "ScopeTwoEmissionsModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class ScopeTwoEmissionsModule extends VuexModule {
  private dataScopeTwo = {} as IScopeTwoEmissionsBomsData;
  private dataScopeTwoDetail = {} as IScopeTwoEmissionsTwelveMonths;
  private errorScopeTwo = {} as ErrorResponse;
  private errorScopeTwoDetail = {} as ErrorResponse;
  private updateRequestScopeTwo = {};
  get dataScopeTwoGetter() {
    return this.dataScopeTwo;
  }
  @Mutation
  getListRequestScopeTwoError(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorScopeTwo = error;
  }
  @Mutation
  getListRequestScopeTwoSuccess(data: IScopeTwoEmissionsBomsData) {
    this.dataScopeTwo = data;
  }
  @Action
  async fetchListRequestScopeTwo(params: IScopeTwoEmissionsBomsParameters) {
    const servicesScopeTwoEmissions = new ScopeTwoEmissions();
    const { data, error } = await servicesScopeTwoEmissions.getScopeTwoRequest(
      params
    );
    if (error) {
      this.getListRequestScopeTwoError(error);
    } else {
      this.getListRequestScopeTwoSuccess(data);
    }
  }
  get dataScopeTwoDetailGetter() {
    return this.dataScopeTwoDetail;
  }
  @Mutation
  getListRequestScopeTwoIDDetailError(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorScopeTwoDetail = error;
  }
  @Mutation
  getListRequestScopeTwoIDDetailSuccess(data: IScopeTwoEmissionsTwelveMonths) {
    this.dataScopeTwoDetail = data;
  }
  @Action
  async fetchListRequestScopeTwoIDDetail(
    params: IScopeTwoEmissionsRequestIDParameters
  ) {
    const servicesScopeTwoEmissions = new ScopeTwoEmissions();
    const { data, error } =
      await servicesScopeTwoEmissions.getScopeTwoRequestDetail(params);
    if (error) {
      this.getListRequestScopeTwoIDDetailError(error);
    } else {
      this.getListRequestScopeTwoIDDetailSuccess(data);
    }
  }

  get dataStatusRemarkScopeTwoGetter() {
    return this.updateRequestScopeTwo;
  }
  @Mutation
  updateListRequestScopeTwoError(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorScopeTwo = error;
  }
  @Mutation
  updateListRequestScopeTwoSuccess(data: IScopeTwoEmissionsRequestDetail) {
    ElMessage.success("Updated status request success.");
    this.updateRequestScopeTwo = data;
  }
  @Action
  async updateListRequestScopeTwo(params: IScopeTwoEmissionsRequestDetail) {
    const servicesScopeTwoEmissions = new ScopeTwoEmissions();
    const { data, error } =
      await servicesScopeTwoEmissions.updateScopeTwoRequestDetail(params);
    if (error) {
      this.updateListRequestScopeTwoError(error);
    } else {
      this.updateListRequestScopeTwoSuccess(data);
    }
  }
}
export default getModule(ScopeTwoEmissionsModule);
