import {
  IScopeTwoEmissionsBomsParameters,
  IScopeTwoEmissionsRequestIDParameters,
  IScopeTwoEmissionsRequestDetail,
} from "@/models/ScopeTwoEmissionsBoms";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const SCOPE2 = "ScopeTwoEmissions";

export class ScopeTwoEmissions extends HttpClient {
  constructor() {
    super(process.env.VUE_APP_DOMAIN_API_SERVER);
  }

  public getScopeTwoRequest = async (
    params: IScopeTwoEmissionsBomsParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE2}/requests`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoRequestDetail = async (
    params: IScopeTwoEmissionsRequestIDParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE2}/requests/${params}`)
      .catch(catchAxiosError);

    return response;
  };

  public updateScopeTwoRequestDetail = async (
    params: IScopeTwoEmissionsRequestDetail
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .patch(`${SCOPE2}/requests/${params.requestId}`, params)
      .catch(catchAxiosError);

    return response;
  };
}
