import {
  IScopeOneScopeThreeBomsParameters,
  IScopeOneScopeThreeRequestIDParameters,
} from "@/models/ScopeOneScopeThreeEmissionsBoms";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const SCOPE1 = "ScopeOneEmissions";
const SCOPE3 = "ScopeThreeEmissions";

export class Scope1Scope3API extends HttpClient {
  constructor() {
    super(process.env.VUE_APP_DOMAIN_API_SERVER);
  }

  public getScopeOneRequest = async (
    params: IScopeOneScopeThreeBomsParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE1}/requests`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeOneRequestDetail = async (
    params: IScopeOneScopeThreeRequestIDParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE1}/requests/${params}`)
      .catch(catchAxiosError);

    return response;
  };

  public updateScopeOneRequestDetail = async (
    params: IScopeOneScopeThreeRequestIDParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .patch(`${SCOPE1}/requests/${params.requestId}`, params)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeThreeRequest = async (
    params: IScopeOneScopeThreeBomsParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE3}/requests`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeThreeRequestDetail = async (
    params: IScopeOneScopeThreeRequestIDParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE3}/requests/${params}`)
      .catch(catchAxiosError);

    return response;
  };
  public updateScopeThreeRequestDetail = async (
    params: IScopeOneScopeThreeRequestIDParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .patch(`${SCOPE3}/requests/${params.requestId}`, params)
      .catch(catchAxiosError);

    return response;
  };
}
