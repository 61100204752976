import {
  IScopeOneScopeThreeBomsParameters,
  IScopeOneScopeThreeBomsData,
  IScopeOneScopeThreeRequestIDParameters,
  IScopeOneScopeThreeRequestDetail,
  IScopeOneScopeTwelveMonths,
} from "@/models/ScopeOneScopeThreeEmissionsBoms";

import { ErrorResponse } from "@/services/axios/error";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";

import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { Scope1Scope3API } from "@/services/scope1scope3Boms";
import { IResponse } from "@/services/axios/entities";

const name = "ScopeOneScopeThreeModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class ScopeOneScopeThreeModule extends VuexModule {
  private dataScopeOne = {} as IScopeOneScopeThreeBomsData;
  private errorScopeOne = {} as ErrorResponse;
  private dataScopeOneDetail = {} as IScopeOneScopeTwelveMonths;
  private dataScopeThreeDetail = {} as IScopeOneScopeThreeBomsData;
  private errorScopeOneDetail = {} as ErrorResponse;
  private dataScopeThree = {} as IScopeOneScopeThreeBomsData;
  private errorScopeThree = {} as ErrorResponse;
  private updateRequestScopeOne = {} as IScopeOneScopeThreeRequestDetail;
  private updateRequestScopeThree = {} as IScopeOneScopeThreeRequestDetail;

  get dataScopeOneGetter() {
    return this.dataScopeOne;
  }
  @Mutation
  getListRequestScopeOneError(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorScopeOne = error;
  }
  @Mutation
  getListRequestScopeOneSuccess(data: IScopeOneScopeThreeBomsData) {
    this.dataScopeOne = data;
  }
  @Action
  async fetchListRequestScopeOne(params: IScopeOneScopeThreeBomsParameters) {
    const servicesScope1Scope3API = new Scope1Scope3API();
    const { data, error } = await servicesScope1Scope3API.getScopeOneRequest(
      params
    );
    if (error) {
      this.getListRequestScopeOneError(error);
    } else {
      this.getListRequestScopeOneSuccess(data);
    }
  }
  get dataScopeOneDetailGetter() {
    return this.dataScopeOneDetail;
  }
  @Mutation
  getListRequestScopeOneIDDetailError(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorScopeOneDetail = error;
  }
  @Mutation
  getListRequestScopeOneIDDetailSuccess(data: IScopeOneScopeTwelveMonths) {
    this.dataScopeOneDetail = data;
  }
  @Action
  async fetchListRequestScopeOneIDDetail(
    params: IScopeOneScopeThreeRequestIDParameters
  ) {
    const servicesScope1Scope3API = new Scope1Scope3API();
    const { data, error } =
      await servicesScope1Scope3API.getScopeOneRequestDetail(params);
    if (error) {
      this.getListRequestScopeOneIDDetailError(error);
    } else {
      this.getListRequestScopeOneIDDetailSuccess(data);
    }
  }

  get dataStatusRemarkScopeOneGetter() {
    return this.updateRequestScopeOne;
  }
  @Mutation
  updateListRequestScopeOneError(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorScopeOne = error;
  }
  @Mutation
  updateListRequestScopeOneSuccess(data: IScopeOneScopeThreeRequestDetail) {
    ElMessage.success("Updated status request success.");
    this.updateRequestScopeOne = data;
  }
  @Action
  async updateListRequestScopeOne(params: IScopeOneScopeThreeRequestDetail) {
    const servicesScope1Scope3API = new Scope1Scope3API();
    const { data, error } =
      await servicesScope1Scope3API.updateScopeOneRequestDetail(params);
    if (error) {
      this.updateListRequestScopeOneError(error);
    } else {
      this.updateListRequestScopeOneSuccess(data);
    }
  }
  // SCOPE 3
  get dataScopeThreeGetter() {
    return this.dataScopeThree;
  }
  @Mutation
  getListRequestScopeThreeError(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorScopeThree = error;
  }
  @Mutation
  getListRequestScopeThreeSuccess(data: IScopeOneScopeThreeBomsData) {
    this.dataScopeThree = data;
  }
  @Action
  async fetchListRequestScopeThree(params: IScopeOneScopeThreeBomsParameters) {
    const servicesScope1Scope3API = new Scope1Scope3API();
    const { data, error } = await servicesScope1Scope3API.getScopeThreeRequest(
      params
    );
    if (error) {
      this.getListRequestScopeThreeError(error);
    } else {
      this.getListRequestScopeThreeSuccess(data);
    }
  }
  get dataScopeThreeDetailGetter() {
    return this.dataScopeThreeDetail;
  }
  @Mutation
  getListRequestScopeThreeIDDetailError(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorScopeThree = error;
  }
  @Mutation
  getListRequestScopeThreeIDDetailSuccess(data: IScopeOneScopeThreeBomsData) {
    this.dataScopeThreeDetail = data;
  }
  @Action
  async fetchListRequestScopeThreeIDDetail(
    params: IScopeOneScopeThreeRequestIDParameters
  ) {
    const servicesScope1Scope3API = new Scope1Scope3API();
    const { data, error } =
      await servicesScope1Scope3API.getScopeThreeRequestDetail(params);
    if (error) {
      this.getListRequestScopeThreeIDDetailError(error);
    } else {
      this.getListRequestScopeThreeIDDetailSuccess(data);
    }
  }

  get dataStatusRemarkScopeThreeGetter() {
    return this.updateRequestScopeThree;
  }
  @Mutation
  updateListRequestScopeThreeError(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorScopeThree = error;
  }
  @Mutation
  updateListRequestScopeThreeSuccess(data: IScopeOneScopeThreeRequestDetail) {
    ElMessage.success("Updated status request success.");
    this.updateRequestScopeThree = data;
  }
  @Action
  async updateListRequestScopeThree(params: IScopeOneScopeThreeRequestDetail) {
    const servicesScope1Scope3API = new Scope1Scope3API();
    const { data, error } =
      await servicesScope1Scope3API.updateScopeThreeRequestDetail(params);
    if (error) {
      this.updateListRequestScopeThreeError(error);
    } else {
      this.updateListRequestScopeThreeSuccess(data);
    }
  }
}
export default getModule(ScopeOneScopeThreeModule);
